function encryptData(data, encryptionKey) {
  // Replace this with a more robust encryption algorithm
  const encryptedData = btoa(data); // Base64 encoding for simplicity
  return encryptedData;
}

function setCookie(name, value, daysToExpire, encryptionKey) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + daysToExpire);

  const encryptedValue = encryptData(value, encryptionKey);

  const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
    encryptedValue
  )}; expires=${expirationDate.toUTCString()}; path=/`;

  document.cookie = cookieString;
}

// Function to decode Base64-encoded data
function decryptData(encryptedData) {
  return atob(encryptedData); // Base64 decoding
}

// Function to read a cookie and decrypt its value
function getCookie(name) {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split("; ");

  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].split("=");
    if (cookie[0] === name) {
      const encryptedValue = cookie[1];
      const decryptedValue = decryptData(encryptedValue);
      return decryptedValue;
    }
  }

  return null;
}

// Function to clear a cookie by name
function clearCookie(name) {
  document.cookie = `${encodeURIComponent(
    name
  )}=;expires=Thu, 01 Jan 1970 00:00:00 UTC path=/;`;
}

function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    const cookieName = cookie[0].trim();

    // Expire the cookie by setting its expiration date to a past date
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}

export { setCookie, getCookie, clearCookie, clearAllCookies };
