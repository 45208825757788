import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import ManageClients from "./clients/index";
import LoadingStateReducer from "./loading-state";
import adminsReducer from "./manageAdmins";
const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  manageClientsData: ManageClients,
  loadingState: LoadingStateReducer,
  adminsData: adminsReducer,
});

export default rootReducer;
