import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "collapse",
  //   icon: <Icon.Home size={20} />,
  //   badge: "warning",
  //   badgeText: "2",
  //   children: [
  //     {
  //       id: "analyticsDash",
  //       title: "Analytics",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/",
  //     },
  //   ],
  // },

  {
    id: "Dashboard",
    title: "Dashboard",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: ["admin", "editor"],
    navLink: "/dashboard",
  },
  {
    id: "Companies",
    title: "Manage Company",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: ["admin", "editor"],
    navLink: "/companies",
  },
  {
    id: "admins",
    title: "Manage Admins",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: ["admin", "editor"],
    navLink: "/admins",
  },
];

export default navigationConfig;
