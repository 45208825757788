const initialState = {
    list: [],
    total: "",
    searchKey: "",
  };
  
  const ManageClientListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "MANAGE_ALL_CLIENTS_RESPONSE":
        return {
          ...state,
          list: [...action.data.list.tenants],
          total: action.data.total,
        };
  
      default:
        return state;
    }
  };
  
  export default ManageClientListReducer;
  