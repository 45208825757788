import React, { useState } from "react";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { useHistory } from "react-router";
import { clearAllCookies, clearCookie } from "../../../services/cookiemanager";

const NavbarUser = (props) => {
  const history = useHistory();
  const logoutClick = () => {
    clearAllCookies();
    clearCookie("fullname");
    clearCookie("userid");
    clearCookie("emailid");
  };
  
  const handleForm = (val) => {
    history.push({
      pathname: `/change-pwd`,
      //state: [getCookie("userid")],
    });
    };

  const UserDropdown = () => {
    return (
      <DropdownMenu right>
        {/* <DropdownItem tag="a" href="/profileview">
          <span className="align-middle">Edit Profile</span>
        </DropdownItem> */}
        {/* <DropdownItem tag="a" href="#">
          <span className="align-middle">My Inbox</span>
        </DropdownItem> */}
        <DropdownItem
          tag="a"
          className="tabs_drop"
          onClick={handleForm}
        >
          <span className="align-middle">Change Password</span>
        </DropdownItem>

        <DropdownItem divider />
        <DropdownItem tag="a" href="/" className="tabs_drop" onClick={logoutClick}>
          <span className="align-middle">Log Out</span>
        </DropdownItem>
      </DropdownMenu>
    );
  };
  return (
    <>
      <div>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600 user_login">
                  {props.userName}
                </span>
              </div>
              <span data-tour="user">
                <img
                  src={props.userImg}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              </span>
            </DropdownToggle>
            <UserDropdown />
          </UncontrolledDropdown>
        </ul>
      </div>
    </>
    
  );
};
export default NavbarUser;
