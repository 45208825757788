const initialState = {
  admins: [],
};

const adminsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ADMINS":
      const data = {
        ...state,
        admins: [...action.payload.admins],
        count: action.payload.count,
      };
      return data;
    default:
      return state;
  }
};

export default adminsReducer;
